

const gallery = [

    '/images/pictures/gallery/01.png',
    '/images/pictures/gallery/02.png',
    '/images/pictures/gallery/03.png',
    '/images/pictures/gallery/04.png',
    '/images/pictures/gallery/05.png',
    '/images/pictures/gallery/06.jpg',
    '/images/pictures/gallery/07.jpg',
    '/images/pictures/gallery/08.jpg',
    '/images/pictures/gallery/09.jpg',
    '/images/pictures/gallery/10.jpg',
    
]


export default gallery